<template>
  <div>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
    >
      <template v-slot:custom_actions="{ item, generalActions }">
        <b-dropdown-item
          title="Regions"
          :to="{ name: 'contact-uses-details', params: { resourceId: item.id } }"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
          />
          <span>{{ $t('labels.details') }}</span>
        </b-dropdown-item>
      </template>
    </TableIndex>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'contact-uses',
      singularName: 'contact-uses',
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
}
</script>
